import React, { useContext } from 'react'
import { ImageAccordion as UDSImageAccordion } from '@telus-uds/components-community.image-accordion'
import { contentfulLoader } from '../Image/contentfulLoader'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'
import { VIEWPORT_SIZES } from '@/constants/VIEWPORT'
import { isObjectDefined } from '@/siteBuilder/utils/isObjectDefined'

export type ImageAccordionProps = {
  accordionHeading: string
  subHeading: string
  reverseColumns: boolean
  vertImageAlign: 'center' | 'flex-start' | 'flex-end'
  accordionItems: AccordionItemProps[]
}

type AccordionItemProps = {
  heading: React.ReactNode
  desktopImage: { [key: string]: any }
  mobileImage: { [key: string]: any }
  desktopImageBlock: React.ReactNode
  mobileImageBlock: React.ReactNode
  children?: React.ReactNode
}

const ImageAccordion = ({
  accordionHeading,
  subHeading,
  reverseColumns,
  vertImageAlign,
  accordionItems,
}: ImageAccordionProps) => {
  const { isWebpSupported } = useContext(SiteBuilderContext)
  const loader = contentfulLoader(isWebpSupported)

  const itemParser = () => {
    const items = []

    accordionItems.map((item) => {
      const { heading, desktopImage, mobileImage, desktopImageBlock, mobileImageBlock, children } = item
      items.push({
        desktopImage: isObjectDefined(desktopImage) && {
          ...desktopImage,
          // Performance: get Image based on viewport
          src: loader({ src: desktopImage.src, width: VIEWPORT_SIZES.xl }),
          loading: 'lazy',
        },
        mobileImage: isObjectDefined(mobileImage) && {
          ...mobileImage,
          // Performance: Mobile image has the width of the MD device
          src: loader({ src: mobileImage.src, width: VIEWPORT_SIZES.md }),
          loading: 'lazy',
        },
        desktopImageBlock,
        mobileImageBlock,
        heading,
        children,
      })
    })

    return items
  }

  return (
    <UDSImageAccordion
      accordionHeading={accordionHeading}
      subHeading={subHeading}
      accordionItems={itemParser()}
      reverseColumns={reverseColumns}
      vertImageAlign={vertImageAlign}
      data-testid="imageAccordion-testid"
    />
  )
}

export default ImageAccordion
